<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <!-- <router-link to="/">
                    <div class="layout-topbar-logo" id="logolink" style="cursor: pointer; outline: none;">
                        <img id="app-logo" alt="poseidon-layout"
                            :src="'layout/images/logo-' + (topbarTheme === 'light' ? 'poseidon' : 'poseidon-dark') + '.png'">
                    </div>
                </router-link> -->
            </div>

            <div class="layout-topbar-right">
                <a class="menu-button" href="#" @click="$emit('menu-button-click', $event)">
                    <i class="pi pi-bars"></i>
                </a>

                <ul class="layout-topbar-actions">
                    <li class="search-item topbar-item" :class="{'active-topmenuitem': activeTopbarItem === 'search'}">
                        <a href="#" @click="onTopbarItemClick($event, 'search')">
                            <span class="topbar-icon">
                                <i class="pi pi-search"></i>
                            </span>
                        </a>

                        <div class="search-input-wrapper">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <InputText placeholder="Search..." />
                            </span>
                        </div>

                        <ul class="fadeInDown">
                            <div class="search-input-wrapper p-fluid">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <InputText placeholder="Search..." @click="$emit('search-click', $event)" />
                                </span>
                            </div>
                        </ul>
                    </li>

                    <li class="topbar-item bookmarks" :class="{'active-topmenuitem': activeTopbarItem === 'bookmarks'}">
                        <a href="#" @click="onTopbarItemClick($event, 'bookmarks')">
                            <span class="topbar-icon">
                                <i class="pi pi-bookmark"></i>
                            </span>
                        </a>                        
                        <ul class="fadeInDown">                                                        
                            <li class="layout-submenu-header">
                                <!-- <div class="profile-info"> -->
                                    <h6 class="header-text">ブックマーク</h6>
                                <!-- </div> -->
                            </li>       
                            <li role="menuitem">
                                <router-link :to="{name:'bookmarkscrud',params:{code:'0'}}">
                                    <i class="pi pi-bookmark"></i>
                                    すべて表示
                                </router-link>
                            </li>                       
                            <template v-for="(bookmark, index) in bookmarks" :key="index">
                                <li role="menuitem">
                                        <router-link :to="{name:'meigarasearch_ext',params:{code:bookmark.bookmark_cd}}">
                                            <img class="menuitem-img" :src="'https://tradersr.com' + bookmark.trendchart_file"/>
                                            <span class="menuitem-span">{{bookmark.bookmark_cd}} {{bookmark.bookmark_name_ja}}</span>
                                        </router-link>
                                </li>
                            </template>
                            <li role="menuitem">
                                <router-link :to="{name:'bookmarkscrud',params:{code:'tradersr'}}">
                                    Myブックマーク
                                    &nbsp;<span class="p-badge">{{mybookmarkscnt}}</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                    <li class="topbar-item news" :class="{'active-topmenuitem': activeTopbarItem === 'news'}">
                        <a href="#" @click="onTopbarItemClick($event, 'news')">
                            <span class="p-overlay-badge topbar-icon">
                                <!-- <i class="pi pi-map" v-badge="6"></i> -->
                                <i class="pi pi-map"></i>
                            </span>
                        </a>
                        <ul class="fadeInDown">                            
                            <li class="layout-submenu-header">
                                <h6 class="header-text">ニュース</h6>
                            </li>               
                            <li role="menuitem">
                                <router-link :to="{name:'meigaranewsrecentl',params:{code:'grida0'}}">
                                    <i class="pi pi-map"></i>
                                    すべて表示
                                </router-link>
                            </li>    
                            <!-- <li role="menuitem">
                                <router-link :to="{name:'meigaranewsrecentl',params:{code:'gridm0001'}}">
                                    <i class="pi pi-map"></i>
                                    Myニュースを見る
                                    &nbsp;<span class="p-badge">{{mynewscnt}}</span>
                                </router-link>
                            </li>                        -->
                            <template v-for="(newsitem, index) in news" :key="index">         
                                <li role="menuitem">
                                    <div class="news-item">
                                        <router-link :to="{name:'meigaranewsrecentl',params:{code:'gridi'+newsitem.id}}">
                                            <span class="menuitem-span">{{newsitem.news_overview}} | {{newsitem.meigara_cd}} {{newsitem.meigara_name}}</span>
                                        </router-link>
                                        <div>
                                            <router-link :to="{name:'meigaranewsrecentl',params:{code:'gridi'+newsitem.id}}">
                                                <span class="menuitem-sub-span">{{newsitem.news_datetime}} {{newsitem.info_source}} {{newsitem.info_source2}}</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </li>
                            </template>
                            <li role="menuitem">
                                <router-link :to="{name:'meigaranewsrecentl',params:{code:'gridm0001'}}">
                                    Myニュース
                                    &nbsp;<span class="p-badge">{{mynewscnt}}</span>
                                </router-link>
                            </li>     
                        </ul>
                    </li>                 

                    <li class="topbar-item notifications" :class="{'active-topmenuitem': activeTopbarItem === 'notifications'}">
                        <a href="#" @click="onTopbarItemClick($event, 'notifications')">
                            <span class="p-overlay-badge topbar-icon">
                                <!-- <i class="pi pi-bell" v-badge="2"></i> -->
                                <i class="pi pi-bell"></i>
                            </span>
                        </a>
                        <ul class="fadeInDown">
                            <li class="layout-submenu-header">
                                <h6 class="header-text">通知</h6>
                                <!-- <span class="p-badge">3</span> -->
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <i class="pi pi-bell"></i>
                                    <div class="notifications-item">
                                        <h6>すべての通知</h6>
                                    </div>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <div class="notifications-item">
                                        <h6>Meeting with <span>AF04</span> Team</h6>
                                        <span>Google Meets</span>
                                    </div>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <div class="notifications-item">
                                        <h6>Task <span>#41</span> is complete</h6>
                                        <span>9 Remaining Tasks</span>
                                    </div>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <div class="notifications-item">
                                        <h6>Order <span>#2255</span> is placed</h6>
                                        <span>Total Amount of <span>$40.45</span></span>
                                    </div>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <div class="notifications-item">
                                        <h6>Order <span>#2255</span> is placed</h6>
                                        <span>Total Amount of <span>$40.45</span></span>
                                    </div>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <div class="notifications-item">
                                        <h6>Order <span>#2255</span> is placed</h6>
                                        <span>Total Amount of <span>$40.45</span></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="topbar-item user-profile" :class="{'active-topmenuitem': activeTopbarItem === 'profile'}">
                        <a href="#" @click="onTopbarItemClick($event, 'profile')">
                            <!-- <img class="profile-image" src="layout/images/avatar-profile.png" alt="demo"> -->
                            <!-- <img class="profile-image" alt="demo"> -->
                            <!-- <span class="topbar-icon">
                                <i class="pi pi-user"></i>
                            </span> -->
                            <!-- <div class="profile-info">
                                <h6>Peter Taylor</h6>
                                <span>Webmaster</span>
                            </div> -->

                            <span class="topbar-icon">
                                <!-- <i class="pi pi-bell" v-badge="2"></i> -->
                                <i class="pi pi-user"></i>
                            </span>
                        </a>

                        <ul class="fadeInDown">
                            <li class="layout-submenu-header">
                                <!-- <img class="profile-image" src="layout/images/avatar-profile.png" alt="demo"> -->
                                <div class="profile-info">
                                    <h6>Admin</h6>
                                    <span>Webmaster</span>
                                </div>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <i class="pi pi-cog"></i>
                                    <h6>Settings</h6>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <i class="pi pi-file"></i>
                                    <h6>Terms of Usage</h6>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <i class="pi pi-compass"></i>
                                    <h6>Support</h6>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <i class="pi pi-power-off"></i>
                                    <h6>Logout</h6>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <a class="layout-rightpanel-button" href="#" @click="$emit('right-panel-click', $event)">
                    <i class="pi pi-ellipsis-h"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import BookmarksCrudService from '@/service/BookmarksCrudService';
import MeigaranewsrecentlService from "@/service/MeigaranewsrecentlService";

export default { 
    data() {
        return {
            perf: null,
            bookmarks: null,
            news: null,
            mynewscnt: null,
            mybookmarkscnt: null
        }
    },
    bookmarksCrudService: null,
    meigaranewsrecentlService: null,
    created() {
        this.bookmarksCrudService = new BookmarksCrudService();
        this.meigaranewsrecentlService = new MeigaranewsrecentlService();
    },

    name: 'AppTopbar',
    emits: ['menu-button-click', 'right-panel-click', 'topbar-item-click', 'search-click'],
    props: {
        topbarTheme: String,
        activeTopbarItem: String
    },
    methods: {
        onTopbarItemClick(event, item) {
			this.$emit('topbar-item-click', {originalEvent: event, item: item});
			event.preventDefault();

            if(item == 'bookmarks'){ 
                this.bookmarksCrudService.getBookmarks('tradersr', 5)
                    .then(d => {
                        this.bookmarks = d.data.bookmarks;
                        this.mybookmarkscnt = d.data.mybookmarkscnt;
                        this.perf = d.perf;
                    }
                );
            }else if(item == 'news'){
                this.meigaranewsrecentlService.getNewsInfo('listh')
                    .then(d => {
                        this.news = d.data.newslist;
                        this.mynewscnt = d.data.mynewscnt;
                        this.perf = d.perf;
                    }
                );	                
            }
        },
        onTopbarSubItemClick_bookmarks(number, event) {
            console.log (number);
            console.log (event);
            event.preventDefault();
        },
        onTopbarSubItemClick(event) {
            // console.log (event);
            event.preventDefault();
        }
    }
};
</script>

<style scoped>
.menuitem-img {
    border: solid 1px #cccccc;
    border-radius: 5px;
    /* background-color: rgba(241, 178, 99, 0.1); */
}
.menuitem-span {
    color: #515c56;
}
.menuitem-sub-span {
    color: rgba(81, 92, 102, 0.6);
    font-size: 12px;
}
</style>