import {createRouter, createWebHashHistory} from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/',
                name: 'dashboard',
                exact: true,
                component: () => import('./components/Dashboard.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Public', label: 'ホーム' }],
                },
            },
            {
                path: '/datavalidation',
                name: 'datavalidation',
                exact: true,
                component: () => import('./components/Datavalidation.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Maintenance', label: 'データ検証' }],
                },
            },
            {
                path: '/themecustomcrud',
                name: 'themecustomcrud',
                exact: true,
                component: () => import('./components/ThemecustomCrud.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Maintenance', label: 'テーマカスタムCRUD' }],
                },
            },
            {
                path: '/themedicupdate',
                name: 'themedicupdate',
                exact: true,
                component: () => import('./components/ThemedicUpdate.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Maintenance', label: 'テーマ辞書更新' }],
                },
            },               
            {
                path: '/newsdicupdate',
                name: 'newsdicupdate',
                exact: true,
                component: () => import('./components/NewsdicUpdate.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Maintenance', label: 'ニュース辞書更新' }],
                },
            },
            {
                path: '/bookmarkscrud/:code',
                name: 'bookmarkscrud',
                exact: true,
                component: () => import('./components/BookmarksCrud.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Maintenance', label: 'ブックマークCRUD' }],
                },
            },                         
            {
                path: '/meigarasearch_base',
                name: 'meigarasearch_base',
                exact: true,
                component: () => import('./components/MeigarasearchBase.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄ベース' }],
                },
            },     
            {
                path: '/meigarasearch_ext/:code',
                name: 'meigarasearch_ext',
                exact: true,
                component: () => import('./components/MeigarasearchExt.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄拡張' }],
                },
            },          
            {
                path: '/meigaranewsrecentl/:code',
                name: 'meigaranewsrecentl',
                exact: true,
                component: () => import('./components/Meigaranewsrecentl.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄ニュース' }],
                },
            },
            {
                path: '/meigaranewsrecentl_lazy/:code',
                name: 'meigaranewsrecentl_lazy',
                exact: true,
                component: () => import('./components/Meigaranewsrecentl_lazy.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄ニュース_lazy' }],
                },
            },   
            {
                path: '/meigaranewsrecentl_quick/:code',
                name: 'meigaranewsrecentl_quick',
                exact: true,
                component: () => import('./components/Meigaranewsrecentl_quick.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄ニュース_quick' }],
                },
            },
            {
                path: '/news/:code',
                component: () => import('./pages/News.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/news/NewsList.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'Search', children: 'ニュース', label: '銘柄ニュース一覧' }],
                        },
                    },
                    {
                        path: 'trend',
                        component: () => import('./components/news/NewsTrend.vue'),
                        meta: {
                            breadcrumb: [{ parent: 'Search', children: 'ニュース', label: '銘柄ニューストレンド' }],
                        },
                    },
                ],
            },
            {
                path: '/meigaranews2list',
                name: 'meigaranews2list',
                exact: true,
                component: () => import('./components/Meigaranews2list.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄ニュース_list' }],
                },
            },                  
            {
                path: '/meigaracompare',
                name: 'meigaracompare',
                exact: true,
                component: () => import('./components/Meigaracompare.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Search', label: '銘柄比較' }],
                },
            },                   
            {
                path: '/meigara',
                name: 'meigara',
                exact: true,
                component: () => import('./components/Meigara.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '上場銘柄' }],
                },
            },
            {
                path: '/meigaradetail',
                name: 'meigaradetail',
                exact: true,
                component: () => import('./components/Meigaradetail.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '上場銘柄詳細' }],
                },
            },            
            {
                path: '/market',
                name: 'market',
                exact: true,
                component: () => import('./components/Market.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '市場区分' }],
                },
            },            
            {
                path: '/industry',
                name: 'industry',
                exact: true,
                component: () => import('./components/Industry.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '33業種' }],
                },
            },
            {
                path: '/theme',
                name: 'theme',
                exact: true,
                component: () => import('./components/Theme.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: 'テーマ' }],
                },
            },             
            {
                path: '/themecustom',
                name: 'themecustom',
                exact: true,
                component: () => import('./components/Themecustom.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: 'テーマカスタム' }],
                },
            },                
            {
                path: '/themedic',
                name: 'themedic',
                exact: true,
                component: () => import('./components/Themedic.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: 'テーマ辞書' }],
                },
            },
            {
                path: '/meigaranews',
                name: 'meigaranews',
                exact: true,
                component: () => import('./components/Meigaranews.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '銘柄ニュース' }],
                },
            },    
            {
                path: '/meigaranews2',
                name: 'meigaranews2',
                exact: true,
                component: () => import('./components/Meigaranews2.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '銘柄ニュース2' }],
                },
            },                  
            {
                path: '/newstrend',
                name: 'newstrend',
                exact: true,
                component: () => import('./components/Newstrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: 'ニューストレンド' }],
                },
            },   
            {
                path: '/newsdic',
                name: 'newsdic',
                exact: true,
                component: () => import('./components/Newsdic.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: 'ニュース辞書' }],
                },
            },                    
            {
                path: '/stocksplit',
                name: 'stocksplit',
                exact: true,
                component: () => import('./components/Stocksplit.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '株式分割銘柄' }],
                },
            },
            {
                path: '/companyprice',
                name: 'companyprice',
                exact: true,
                component: () => import('./components/Companyprice.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '株価' }],
                },
            },        
            {
                path: '/companypriceetc',
                name: 'companypriceetc',
                exact: true,
                component: () => import('./components/Companypriceetc.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '株価その他' }],
                },
            },          
            {
                path: '/wwindex',
                name: 'wwindex',
                exact: true,
                component: () => import('./components/Wwindex.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '主要指数' }],
                },
            },
            {
                path: '/industryidxtrend',
                name: 'industryidxtrend',
                exact: true,
                component: () => import('./components/IndustryidxTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '主要指数', label: '33業種:D' }],
                },
            },   
            {
                path: '/japanidxtrend',
                name: 'japanidxtrend',
                exact: true,
                component: () => import('./components/JapanidxTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '主要指数', label: '日本:J' }],
                },
            },      
            {
                path: '/usidxtrend',
                name: 'usidxtrend',
                exact: true,
                component: () => import('./components/UsidxTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '主要指数', label: '米国:U' }],
                },
            },                   
            {
                path: '/relastats',
                name: 'relastats',
                exact: true,
                component: () => import('./components/Relastats.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '関係R' }],
                },
            },  
            {
                path: '/relastatsetc',
                name: 'relastatsetc',
                exact: true,
                component: () => import('./components/Relastatsetc.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '関係統計その他' }],
                },
            },     
            {
                path: '/relation',
                name: 'relation',
                exact: true,
                component: () => import('./components/Relation.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Table Base', label: '関係R銘柄' }],
                },
            },                      
            {
                path: '/themetrend',
                name: 'themetrend',
                exact: true,
                component: () => import('./components/ThemeTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R2 テーマ:T' }],
                },
            }, 
            {
                path: '/markettrend',
                name: 'markettrend',
                exact: true,
                component: () => import('./components/MarketTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R3 マーケット:K' }],
                },
            },            
            {
                path: '/meigaratrend',
                name: 'meigaratrend',
                exact: true,
                component: () => import('./components/MeigaraTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: 'その他', label: '銘柄:M' }],
                },
            },             
            {
                path: '/industrytrend',
                name: 'industrytrend',
                exact: true,
                component: () => import('./components/IndustryTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R1 33業種:I' }],
                },
            },
            {
                path: '/topixtrend',
                name: 'topixtrend',
                exact: true,
                component: () => import('./components/TopixTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R4 TOPIX:X' }],
                },
            },     
            {
                path: '/yieldtrend',
                name: 'yieldtrend',
                exact: true,
                component: () => import('./components/YieldTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R7 利回り:Y' }],
                },
            },
            {
                path: '/mratiotrend',
                name: 'mratiotrend',
                exact: true,
                component: () => import('./components/MratioTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R8 信用倍率:C' }],
                },
            },
            {
                path: '/taishakutrend',
                name: 'taishakutrend',
                exact: true,
                component: () => import('./components/TaishakuTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R9 貸借:H' }],
                },
            },
            {
                path: '/rstrengthtrend',
                name: 'rstrengthtrend',
                exact: true,
                component: () => import('./components/RstrengthTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R11 比較銘柄優位性:G' }],
                },
            },
            {
                path: '/continuitytrend',
                name: 'continuitytrend',
                exact: true,
                component: () => import('./components/ContinuityTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R13 株価連続性:A' }],
                },
            },
            {
                path: '/atratiotrend',
                name: 'atratiotrend',
                exact: true,
                component: () => import('./components/AtratioTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R14 株価前日比:R' }],
                },
            },
            {
                path: '/vratiotrend',
                name: 'vratiotrend',
                exact: true,
                component: () => import('./components/VratioTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R15 出来高前日比:F' }],
                },
            },
            {
                path: '/settlementtrend',
                name: 'settlementtrend',
                exact: true,
                component: () => import('./components/SettlementTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R12 決算:S' }],
                },
            },    
            {
                path: '/ma5drtrend',
                name: 'ma5drtrend',
                exact: true,
                component: () => import('./components/Ma5drTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R16 MA5乖離率:6' }],
                },
            },    
            {
                path: '/ma10drtrend',
                name: 'ma10drtrend',
                exact: true,
                component: () => import('./components/Ma10drTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R17 MA10乖離率:7' }],
                },
            },
            {
                path: '/ma25drtrend',
                name: 'ma25drtrend',
                exact: true,
                component: () => import('./components/Ma25drTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R18 MA25乖離率:8' }],
                },
            },
            {
                path: '/ma50drtrend',
                name: 'ma50drtrend',
                exact: true,
                component: () => import('./components/Ma50drTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R19 MA50乖離率:9' }],
                },
            },
            {
                path: '/ma75drtrend',
                name: 'ma75drtrend',
                exact: true,
                component: () => import('./components/Ma75drTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R20 MA75乖離率:0' }],
                },
            },
            {
                path: '/mrstrengthtrend',
                name: 'mrstrengthtrend',
                exact: true,
                component: () => import('./components/MrstrengthTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R21 市場優位性:L' }],
                },
            },
            {
                path: '/newsmeigaratrend',
                name: 'newsmeigaratrend',
                exact: true,
                component: () => import('./components/NewsmeigaraTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R22 NEWS:N' }],
                },
            },
            {
                path: '/gratiotrend',
                name: 'gratiotrend',
                exact: true,
                component: () => import('./components/GratioTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R23 GU率:P' }],
                },
            },
            {
                path: '/volaratiotrend',
                name: 'volaratiotrend',
                exact: true,
                component: () => import('./components/VolaratioTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R24 ボラ:Q' }],
                },
            },
            {
                path: '/pertrend',
                name: 'pertrend',
                exact: true,
                component: () => import('./components/PerTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R5 PER:E' }],
                },
            },   
            {
                path: '/pbrtrend',
                name: 'pbrtrend',
                exact: true,
                component: () => import('./components/PbrTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R6 PBR:B' }],
                },
            },  
            {
                path: '/marketvaluetrend',
                name: 'marketvaluetrend',
                exact: true,
                component: () => import('./components/MarketvalueTrend.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Trend Chart', children: '関係R', label: 'R9 時価総額:V' }],
                },
            },              
            {
                path: '/company',
                name: 'company',
                exact: true,
                component: () => import('./components/Company.vue'),
                meta: {
                    breadcrumb: [{ parent: 'View Base', label: '上場企業' }],
                },
            },       
            {
                path: '/companypricelatest',
                name: 'companypricelatest',
                exact: true,
                component: () => import('./components/Companypricelatest.vue'),
                meta: {
                    breadcrumb: [{ parent: 'View Base', label: '株価最新' }],
                },
            },   
            {
                path: '/industrymcnt',
                name: 'industrymcnt',
                exact: true,
                component: () => import('./components/Industrymcnt.vue'),
                meta: {
                    breadcrumb: [{ parent: 'View Base', label: '業種及び企業' }],
                },
            },
            {
                path: '/meigaranewsrecent',
                name: 'meigaranewsrecent',
                exact: true,
                component: () => import('./components/Meigaranewsrecent.vue'),
                meta: {
                    breadcrumb: [{ parent: 'View Base', label: '銘柄ニュース最新' }],
                },
            },
            {
                path: '/meigaranews2recent',
                name: 'meigaranews2recent',
                exact: true,
                component: () => import('./components/Meigaranews2recent.vue'),
                meta: {
                    breadcrumb: [{ parent: 'View Base', label: '銘柄ニュース2最新' }],
                },
            },
            {
                path: '/meigaranewsview',
                name: 'meigaranewsview',
                exact: true,
                component: () => import('./components/Meigaranewsview.vue'),
                meta: {
                    breadcrumb: [{ parent: 'View Base', label: '銘柄ニュースビュー' }],
                },
            },       
            {
                path: '/wwindexchart',
                name: 'wwindexchart',
                exact: true,
                component: () => import('./components/Wwindexchart.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Chart Base', label: '指数チャート' }],
                },
            },  
            {
                path: '/meigarachart/:code',
                name: 'meigarachart',
                exact: true,
                component: () => import('./components/Meigarachart.vue'),
                meta: {
                    breadcrumb: [{ parent: 'Chart Base', label: '銘柄チャート' }],
                },
            },                                                                                                                                            
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;