<template>
    <div :class="containerClass" @click="onDocumentClick($event)">
            <AppTopBar :topbarTheme="topbarTheme" :activeTopbarItem="activeTopbarItem"
                @menu-button-click="onMenuButtonClick($event)" @right-panel-click="onRightPanelButtonClick" @topbar-item-click="onTopbarItemClick" @search-click="onSearchClick"
            ></AppTopBar>

        <AppRightPanel @right-panel-click="onRightPanelClick" ></AppRightPanel>

        <div class="menu-wrapper" @click="onMenuClick($event)">
            <AppMenu :model="menu" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" :menuMode="menuMode"
                @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
        </div>

        <div class="layout-main">
            <AppBreadcrumb></AppBreadcrumb>

            <div class="layout-content">
                <router-view :key="$route.params.code"/>
            </div>

            <AppFooter></AppFooter>
        </div>

        <!--<AppConfig v-model:theme="theme" v-model:layoutMode="layoutMode" v-model:menuMode="menuMode" v-model:menuTheme="menuTheme" v-model:topbarTheme="topbarTheme" :configActive="configActive" :configClick="configClick" @config-click="onConfigClick" @config-button-click="onConfigButtonClick"></AppConfig>-->

        <div v-if="staticMenuMobileActive" class="layout-mask modal-in"></div>
    </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from "./AppTopbar";
import AppMenu from "./AppMenu";
import AppFooter from "./AppFooter";
//import AppConfig from "./AppConfig";
import AppRightPanel from "./AppRightPanel";
import AppBreadcrumb from "./AppBreadcrumb";
export default {
    data() {
        return {
            theme: 'denim',
            topbarTheme: "light",
            menuTheme: "light",
            layoutMode: "light",
            menuMode: "static",
            activeTopbarItem: null,
            menuActive: false,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarMenuActive: false,
            rightPanelClick: false,
            rightPanelActive: false,
            configActive: false,
            configClick: false,
            menu: [
                {
                    label: "PUBLIC", icon: "pi pi-fw pi-home",
                    items: [
                        {label: "ホーム", icon: "pi pi-fw pi-home", to: "/"},               
                    ],
                },
                {
                    label: "ADMINISTRATION", icon: "pi pi-fw pi-align-left",
                    items: [                      
                        {
                            label: "Maintenance", icon: "pi pi-fw pi-bars",
                            items: [
                                {label: "データ検証", icon: "pi pi-fw pi-circle-off", to: "/datavalidation"},
                                {label: "テーマカスタムCRUD", icon: "pi pi-fw pi-circle-off", to: "/themecustomcrud"},
                                {label: "テーマ辞書更新", icon: "pi pi-fw pi-circle-off", to: "/themedicupdate"},
                                {label: "ニュース辞書更新", icon: "pi pi-fw pi-circle-off", to: "/newsdicupdate"},
                                {label: "ブックマークCRUD", icon: "pi pi-fw pi-circle-off", to: "/bookmarkscrud/0"}
                            ],
                        },
                        {
                            label: "Search", icon: "pi pi-fw pi-bars",
                            items: [
                                {label: "銘柄ベース", icon: "pi pi-fw pi-circle-off", to: "/meigarasearch_base"},
                                {label: "銘柄拡張", icon: "pi pi-fw pi-circle-off", to: "/meigarasearch_ext/7203"},
                                {label: "銘柄ニュース", icon: "pi pi-fw pi-circle-off", to: "/meigaranewsrecentl/grida0"},
                                {label: "銘柄ニュース_lazy", icon: "pi pi-fw pi-circle-off", to: "/meigaranewsrecentl_lazy/grida0"},
                                {label: "銘柄ニュース_quick", icon: "pi pi-fw pi-circle-off", to: "/meigaranewsrecentl_quick/grida0"},
                                {label: "銘柄ニュース2_optim", icon: "pi pi-fw pi-circle-off", to: "/news/ra"},
                                {label: "銘柄ニュース2_list", icon: "pi pi-fw pi-circle-off", to: "/meigaranews2list"},
                                {label: "銘柄比較", icon: "pi pi-fw pi-circle-off", to: "/meigaracompare"},
                            ],
                        },                                                
                        {
                            label: "Trend Chart", icon: "pi pi-fw pi-bars",
                            items: [  
                                {
                                    label: "関係R", icon: "pi pi-fw pi-bars",
                                    items: [
                                        {label: "R1 33業種:I0", icon: "pi pi-fw pi-circle-off", to: "/industrytrend"},
                                        {label: "R2 テーマ:T0", icon: "pi pi-fw pi-circle-off", to: "/themetrend"},
                                        {label: "R3 マーケット:K0", icon: "pi pi-fw pi-circle-off", to: "/markettrend"},
                                        {label: "R4 TOPIX:X0", icon: "pi pi-fw pi-circle-off", to: "/topixtrend"},
                                        {label: "R5 PER:E0", icon: "pi pi-fw pi-circle-off", to: "/pertrend"},
                                        {label: "R6 PBR:B0", icon: "pi pi-fw pi-circle-off", to: "/pbrtrend"},
                                        {label: "R7 利回り:Y0", icon: "pi pi-fw pi-circle-off", to: "/yieldtrend"},
                                        {label: "R8 信用倍率:C0", icon: "pi pi-fw pi-circle-off", to: "/mratiotrend"},
                                        {label: "R9 貸借:H0", icon: "pi pi-fw pi-circle-off", to: "/taishakutrend"},
                                        {label: "R10 時価総額:V0", icon: "pi pi-fw pi-circle-off", to: "/marketvaluetrend"},
                                        {label: "R11 比較銘柄優位性:G0", icon: "pi pi-fw pi-circle-off", to: "/rstrengthtrend"},
                                        {label: "R12 決算:S0", icon: "pi pi-fw pi-circle-off", to: "/settlementtrend"},
                                        {label: "R13 株価連続性:A0", icon: "pi pi-fw pi-circle-off", to: "/continuitytrend"},
                                        {label: "R14 株価前日比:R0", icon: "pi pi-fw pi-circle-off", to: "/atratiotrend"},
                                        {label: "R15 出来高前日比:F0", icon: "pi pi-fw pi-circle-off", to: "/vratiotrend"},
                                        {label: "R16 MA5乖離率:60", icon: "pi pi-fw pi-circle-off", to: "/ma5drtrend"},
                                        {label: "R17 MA10乖離率:70", icon: "pi pi-fw pi-circle-off", to: "/ma10drtrend"},
                                        {label: "R18 MA25乖離率:80", icon: "pi pi-fw pi-circle-off", to: "/ma25drtrend"},
                                        {label: "R19 MA50乖離率:90", icon: "pi pi-fw pi-circle-off", to: "/ma50drtrend"},
                                        {label: "R20 MA75乖離率:00", icon: "pi pi-fw pi-circle-off", to: "/ma75drtrend"},
                                        {label: "R21 市場優位性:L0", icon: "pi pi-fw pi-circle-off", to: "/mrstrengthtrend"},
                                        {label: "R22 NEWS:N0", icon: "pi pi-fw pi-circle-off", to: "/newsmeigaratrend"},
                                        {label: "R23 GU率:P0", icon: "pi pi-fw pi-circle-off", to: "/gratiotrend"},
                                        {label: "R24 ボラ:Q0", icon: "pi pi-fw pi-circle-off", to: "/volaratiotrend"},
                                    ],
                                },
                                {
                                    label: "主要指数", icon: "pi pi-fw pi-bars",
                                    items: [
                                        {label: "米国:U0", icon: "pi pi-fw pi-circle-off", to: "/usidxtrend"},
                                        {label: "日本:J0", icon: "pi pi-fw pi-circle-off", to: "/japanidxtrend"},
                                        {label: "33業種:D0", icon: "pi pi-fw pi-circle-off", to: "/industryidxtrend"},
                                    ],
                                },                                                                                                                                                   
                                {
                                    label: "その他", icon: "pi pi-fw pi-bars",
                                    items: [
                                        {label: "銘柄:M0", icon: "pi pi-fw pi-circle-off", to: "/meigaratrend"},
                                    ],
                                },   
                            ],
                        },
                        {
                            label: "Chart Base", icon: "pi pi-fw pi-bars",
                            items: [
                                {
                                    label: "指数チャート", icon: "pi pi-fw pi-chart-line", to: "/wwindexchart",
                                },
                                {
                                    label: "銘柄チャート", icon: "pi pi-fw pi-chart-line", to: "/meigarachart/7203",
                                },
                            ],
                        },
                        {
                            label: "View Base", icon: "pi pi-fw pi-bars",
                            items: [
                                {label: "上場企業", icon: "pi pi-fw pi-circle-off", to: "/company"},
                                {label: "株価最新", icon: "pi pi-fw pi-circle-off", to: "/companypricelatest"},
                                {label: "業種及び企業", icon: "pi pi-fw pi-circle-off", to: "/industrymcnt"},
                                {label: "銘柄ニュース最新", icon: "pi pi-fw pi-circle-off", to: "/meigaranewsrecent"},
                                {label: "銘柄ニュース2最新", icon: "pi pi-fw pi-circle-off", to: "/meigaranews2recent"},
                                {label: "銘柄ニュースビュー", icon: "pi pi-fw pi-circle-off", to: "/meigaranewsview"}
                            ],
                        },                         
                        {
                            label: "Table Base",icon: "pi pi-fw pi-bars",
                            items: [
                                {label: "上場銘柄", icon: "pi pi-fw pi-circle-off", to: "/meigara"},
                                {label: "上場銘柄詳細", icon: "pi pi-fw pi-circle-off", to: "/meigaradetail"},
                                {label: "市場区分", icon: "pi pi-fw pi-circle-off", to: "/market"},
                                {label: "33業種", icon: "pi pi-fw pi-circle-off", to: "/industry"},
                                {label: "テーマ", icon: "pi pi-fw pi-circle-off", to: "/theme"},
                                {label: "テーマカスタム", icon: "pi pi-fw pi-circle-off", to: "/themecustom"},                        
                                {label: "テーマ辞書", icon: "pi pi-fw pi-circle-off", to: "/themedic"},
                                {label: "銘柄ニュース", icon: "pi pi-fw pi-circle-off", to: "/meigaranews"},
                                {label: "銘柄ニュース2", icon: "pi pi-fw pi-circle-off", to: "/meigaranews2"},
                                {label: "ニューストレンド", icon: "pi pi-fw pi-circle-off", to: "/newstrend"},
                                {label: "ニュース辞書", icon: "pi pi-fw pi-circle-off", to: "/newsdic"},
                                {label: "株式分割銘柄", icon: "pi pi-fw pi-circle-off", to: "/stocksplit"},
                                {label: "株価", icon: "pi pi-fw pi-circle-off", to: "/companyprice"},
                                {label: "株価その他", icon: "pi pi-fw pi-circle-off", to: "/companypriceetc"},  
                                {label: "主要指数", icon: "pi pi-fw pi-circle-off", to: "/wwindex"},
                                {label: "関係R", icon: "pi pi-fw pi-circle-off", to: "/relastats"},
                                {label: "関係R銘柄", icon: "pi pi-fw pi-circle-off", to: "/relation"},                                
                                {label: "関係Rその他", icon: "pi pi-fw pi-circle-off", to: "/relastatsetc"},
                            ],
                        },                                                                                              
                    ],
                },                
            ],
        };
    },
    computed: {
        containerClass() {
            return [
                "layout-wrapper",
                {
                    "layout-overlay": this.menuMode === "overlay",
                    "layout-static": this.menuMode === "static",
                    'layout-horizontal': this.menuMode === 'horizontal',
                    "layout-overlay-active": this.overlayMenuActive,
                    "layout-mobile-active": this.staticMenuMobileActive,
                    "layout-static-active": !this.staticMenuDesktopInactive && this.menuMode === "static",
                    "layout-rightpanel-active": this.rightPanelActive,
                    "layout-rtl": this.$appState.isRTL,
                    "p-input-filled": this.$primevue.config.inputStyle === "filled",
                    "p-ripple-disabled": !this.$primevue.config.ripple,
                },
                'layout-menu-'+ this.menuTheme+ ' layout-topbar-'+ this.topbarTheme
            ];
        },
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
    },
    methods: {
        onDocumentClick() {
            if (!this.searchClick) {
                this.searchActive = false;
            }

            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.userMenuClick) {
                this.topbarMenuActive = false;
            }

            if (!this.rightPanelClick) {
                this.rightPanelActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal()) {
					EventBus.emit('reset-active-index');
					this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.topbarItemClick = false;
            this.rightPanelClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.rotateMenuButton = !this.rotateMenuButton;
            this.topbarMenuActive = false;
            this.menuClick = true;

            if (this.isOverlay() && !this.isMobile()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
			if (!event.item.items) {
				EventBus.emit('reset-active-index');
				this.hideOverlayMenu();
			}
			if (!event.item.items && this.isHorizontal()) {
                this.menuActive = false;
            }
        },
		onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onRightPanelClick() {
            this.rightPanelClick = true;
        },
        onRightPanelButtonClick(event) {
            this.rightPanelClick = true;
            this.rightPanelActive = !this.rightPanelActive;
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) {
                this.activeTopbarItem = null;
            }
            else {
                this.activeTopbarItem = event.item;
            }

            if (event.item === 'search') {
                this.searchActive = !this.searchActive;
                this.searchClick = !this.searchClick;
            }

            event.originalEvent.preventDefault();
        },
        onSearchClick(event) {
            this.searchClick = true;
            event.stopPropagation();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                    'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isTablet() {
            const width = window.innerWidth;
            return width <= 1024 && width > 640;
        },
        isDesktop() {
            return window.innerWidth > 992;
        },
        isMobile() {
            return window.innerWidth <= 640;
        },
        isOverlay() {
            return this.menuMode === 'overlay';
        },
        isStatic() {
            return this.menuMode === 'static';
        },
        isHorizontal() {
            return this.menuMode === 'horizontal';
        }
    },
    components: {
        AppTopBar,
        AppMenu,
        AppFooter,
        //AppConfig,
        AppRightPanel,
        AppBreadcrumb,
    }
};
</script>

<style lang="scss">
@import "App.scss";
</style>
